import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useContactFormStore = defineStore('contactForm', () => {
  const phoneCookie = useCookie<string|null>('contact-form-phone')
  const nameCookie = useCookie<string|null>('contact-form-name')
  const emailCookie = useCookie<string|null>('contact-form-email')
  const legalCookie = useCookie<boolean>('contact-form-legal')
  const submittedCookie = useCookie<boolean>('contact-form-submitted')

  const getUserPhone = () => {
    const encodedPhone = phoneCookie.value
    return encodedPhone ? atob(encodedPhone) : ''
  }

  const getUserName = () => {
    const encodedName = nameCookie.value
    return encodedName ? atob(encodedName) : ''
  }

  const getUserEmail = () => {
    const encodedEmail = emailCookie.value
    return encodedEmail ? atob(encodedEmail) : ''
  }

  const getLegalConsent = () => {
    return !!legalCookie.value
  }

  const getContactFormSubmitted = () => {
    return !!submittedCookie.value
  }

  const userName = ref<string>(getUserName())
  const userEmail = ref<string>(getUserEmail())
  const userPhone = ref<string>(getUserPhone())
  const legalConsent = ref<boolean>(getLegalConsent())
  const contactFormSubmitted = ref<boolean>(getContactFormSubmitted())

  const updateUserPhone = (phone: string, contactFormSubmitted = true) => {
    userPhone.value = phone
    phoneCookie.value = btoa(phone)

    if (contactFormSubmitted) {
      updateContactFormSubmitted(true)
    }
  }

  const clearUserPhone = () => {
    userPhone.value = ''
    phoneCookie.value = null
    updateContactFormSubmitted(false)
  }

  const updateUserName = (name: string) => {
    userName.value = name
    nameCookie.value = btoa(name)
  }

  const clearUserName = () => {
    userName.value = ''
    nameCookie.value = null
  }

  const updateUserEmail = (email: string) => {
    userEmail.value = email
    emailCookie.value = btoa(email)
  }

  const clearUserEmail = () => {
    userEmail.value = ''
    emailCookie.value = null
  }

  const updateLegalConsent = (value: boolean) => {
    legalConsent.value = value
    legalCookie.value = value
  }

  const updateContactFormSubmitted = (value: boolean) => {
    contactFormSubmitted.value = value
    submittedCookie.value = value
  }

  return {
    userPhone,
    clearUserPhone,
    updateUserPhone,
    userName,
    clearUserName,
    updateUserName,
    userEmail,
    clearUserEmail,
    updateUserEmail,
    legalConsent,
    updateLegalConsent,
    contactFormSubmitted
  }
})
